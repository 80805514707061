export interface AppCoachPlayersCategoriesListFormRemove {
  id: string;
  organization_id: string;
  content: {
    name: string;
    from: number;
    to: number;
  };
}

export class AppCoachPlayersCategoriesListFormRemove implements AppCoachPlayersCategoriesListFormRemove {
  id: string;
  organization_id: string;
  content: {
    name: string;
    from: number;
    to: number;
  };

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.content = {
      name: "",
      from: null,
      to: null,
    };
  }
}