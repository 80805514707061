
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachPlayersNavigationVue from "@/components/coach/players/navigation.vue";
import AppCoachPlayersCategoriesListIndexVue from "@/views/app/coach/players/categories/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachPlayersNavigationVue,
    AppCoachPlayersCategoriesListIndexVue,
  },
})
export default class AppCoachPlayersCategoriesIndexVue extends Vue {}
